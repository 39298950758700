import React from 'react';
import styled from 'styled-components';

const SocialLinks = () => {
  return (
    <Wrapper>
      <div>
        <a href="https://twitter.com/DaiseeAi" target="_blank" rel="noreferrer">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
            <title>twitter</title>
            <path
              d="M23 5.13c-.81.36-1.69.61-2.61.72.94-.56 1.66-1.45 2-2.51-.88.52-1.85.9-2.89 1.1A4.558 4.558 0 0016.18 3a4.543 4.543 0 00-4.42 5.58c-3.78-.19-7.13-2-9.37-4.75-.39.67-.62 1.45-.62 2.28 0 1.58.8 2.97 2.02 3.78-.75-.02-1.45-.23-2.06-.57v.06c0 2.2 1.57 4.04 3.65 4.45-.38.12-.78.17-1.19.17-.29 0-.58-.03-.85-.08a4.557 4.557 0 004.25 3.16 9.112 9.112 0 01-5.64 1.95c-.37 0-.73-.02-1.08-.06 2.01 1.29 4.4 2.04 6.97 2.04 8.36 0 12.93-6.92 12.93-12.93 0-.2 0-.39-.01-.59.86-.65 1.63-1.45 2.24-2.36z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
      <div>
        <a href="https://www.youtube.com/channel/UC7gZ9dsXZn3hYx7LcC55yQQ" target="_blank" rel="noreferrer">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
            <title>youtube</title>
            <path
              d="M23 12s0-3.85-.46-5.58c-.25-.95-1-1.7-1.94-1.96C18.88 4 12 4 12 4s-6.88 0-8.6.46c-.95.25-1.69 1.01-1.94 1.96C1 8.15 1 12 1 12s.04 3.85.5 5.58c.25.95 1 1.7 1.95 1.96 1.71.46 8.59.46 8.59.46s6.88 0 8.6-.46c.95-.25 1.69-1.01 1.94-1.96.46-1.73.42-5.58.42-5.58zm-13 3.27V8.73L15.5 12 10 15.27z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
      <div>
        <a href="https://www.linkedin.com/company/daisee/" target="_blank" rel="noreferrer">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
            <title>linkedin</title>
            <path
              d="M21.37 1H2.62C1.73 1 1 1.71 1 2.58v18.83c0 .88.73 1.59 1.62 1.59h18.75c.9 0 1.63-.71 1.63-1.59V2.58C23 1.71 22.27 1 21.37 1zM7.53 19.75H4.26V9.25h3.27v10.5zM5.89 7.81C4.85 7.81 4 6.96 4 5.92s.84-1.89 1.89-1.89c1.04 0 1.89.85 1.89 1.89.01 1.04-.84 1.89-1.89 1.89zm13.86 11.94h-3.26v-5.1c0-1.22-.02-2.78-1.7-2.78-1.7 0-1.96 1.33-1.96 2.7v5.19H9.57V9.26h3.13v1.43h.04c.44-.83 1.5-1.7 3.09-1.7 3.3 0 3.91 2.17 3.91 5v5.76h.01z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
display: grid;
grid-template-columns: repeat(3, 48px);
padding-bottom: .75rem;
justify-items: end;
margin-top: -0.15rem;
svg {
  transition: opacity 0.15s linear;
}

svg:hover {
  opacity: 0.5;
}

@media all and (max-width: 36.625em) {
    margin: 0 auto;
    justify-items: unset;
}

@media all and (max-width: 20em) {
 padding-bottom: .25rem;
}
`;

export default SocialLinks;