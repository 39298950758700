import { Link, PageProps, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Img, { FluidObject } from 'gatsby-image'
import { transparentize } from 'polished'
import { Mail } from 'react-feather'
import styled from 'styled-components'
import Container from '../Container/index'
import SocialLinks from '../SocialLinks/SocialLinks'

interface Data {
  node: {
    id: string
    name: string
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const accreditationList = [
  {
    id: 'soc2',
    link: 'https://www.aicpa-cima.com/topic/audit-assurance/audit-and-assurance-greater-than-soc-2/'
  },
  {
    id: 'gdpr',
    link: 'https://gdpr.eu/'
  },
  {
  id: 'hipaa',
  link: 'https://compliancy-group.com/hipaa-compliance-verification/'
  },
  {
    id: 'regtech',
    link: 'https://regtech.org.au/'
  },
  {
    id: 'auscontact',
    link: 'https://www.auscontact.com.au/'
  },
  {
    id: 'csia',
    link: 'https://www.csia.com.au/'
  },
  {
    id: 'ccnnz',
    link: 'https://ccnnz.org.nz/'
  }
]

const offices = [
  {
    city: 'Sydney',
    address: 'Level 4/11 York, Sydney, 2000, NSW, Australia',
    query: 'Stone+%26+Chalk+Sydney'
  },
  {
    city: 'Melbourne',
    address: 'Stone and Chalk, 710 Collins Street, Docklands, 3008, VIC, Australia'
  },
  {
    city: 'Dallas',
    address: 'Dallas Galleria Tower one 13355 Noel Road, Suite 1100, 75240, Texas, USA'
  }
]

const Footer: React.FC<{ location?: PageProps['location'] }> = ({ location }) => {
  const data = useStaticQuery(graphql`
    query Certification {
      accreditations: allFile(filter: { relativeDirectory: { eq: "marquee/certification" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const linkedAcreditation = accreditationList.map(el => {
    const obj = data.accreditations.edges.find((x: Data) => x.node.name === el.id)

    return {
      ...el,
      imgSrc: obj?.node?.childImageSharp?.fluid || null
    }
  })

  return (
    <StyledFooter>
      <Container>
        <FooterGrid>
          <HeadingWrapper>
            <Heading>Hear between the lines</Heading>
          </HeadingWrapper>
          <Contact>
            <ContactWrapper>
              <ContactRow>
                <ContactInfoWrapper>
                  <Mail style={{ marginRight: '1.5rem' }} strokeWidth='1' />
                  <a href='mailto:info@daisee.com'>info@daisee.com</a>
                </ContactInfoWrapper>
              </ContactRow>
            </ContactWrapper>
            <OfficeWrapper>
              {offices.map(office => (
                <a
                  key={office.city}
                  href={`https://maps.google.com/?q=${office?.query || office.address}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Office key={office.city}>
                    <City>{office.city}</City>
                    <p>{office.address}</p>
                  </Office>
                </a>
              ))}
            </OfficeWrapper>
          </Contact>
          <Accreditation>
            {linkedAcreditation.map(el => {
              return (
                <ImgWrapper key={el.id}>
                  {!location?.pathname?.startsWith('/discovery_vds') ? (
                    <a href={el.link} target='_blank' rel='noreferrer'>
                      <Img fluid={el.imgSrc} alt={el.id} imgStyle={{ objectFit: 'contain' }} />
                    </a>
                  ) : (
                    <Img fluid={el.imgSrc} alt={el.id} imgStyle={{ objectFit: 'contain' }} />
                  )}
                </ImgWrapper>
              )
            })}
          </Accreditation>
          <Bottom>
            <Privacy>
              <span>© {new Date().getFullYear()} daisee Pty Ltd</span>
              <StyledLink to='/privacy-policy/'>Privacy Policy</StyledLink>
              <StyledLink to='https://daiseeai.statuspage.io/'>Status Page</StyledLink>
            </Privacy>
            {!location?.pathname?.startsWith('/discovery_vds') && <SocialLinks />}
          </Bottom>
        </FooterGrid>
      </Container>
    </StyledFooter>
  )
}

const OfficeWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  height: fit-content;
`

const Office = styled.address`
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  font-style: normal;
  gap: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }

  @media all and (max-width: 48em) {
    font-size: 0.875rem;
  }

  @media all and (max-width: 36.625em) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`

const City = styled.strong`
  font-weight: 500;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1;

  @media all and (max-width: 36.625em) {
    display: block;
  }
`

const StyledLink = styled(Link)``

const Privacy = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  width: fit-content;
  line-height: 1;
  padding-bottom: 1.5rem;

  @media all and (max-width: 36.625em) {
    padding-bottom: 1rem;
    gap: 0.5rem;
    width: 100%;
  }

  * {
    opacity: 60%;
    font-weight: 300;
    font-size: 0.875rem;
    transition: opacity 0.5s ease, color 0.5s ease;

    @media all and (max-width: 64em) {
      font-size: 0.75rem;
    }

    @media all and (max-width: 26.563em) {
      font-size: 0.65rem;
      line-height: 1.7;
    }
  }

  a:hover {
    opacity: 100%;
  }
`

const ImgWrapper = styled.div`
  height: 5rem;
  width: 100%;
  justify-self: stretch;

  @media all and (max-width: 36.625em) {
    height: 3rem;
  }

  div {
    height: 4rem;

    @media all and (max-width: 36.625em) {
      height: 3rem;
    }
  }
`

const Accreditation = styled.div`
  padding: 3rem 0;
  grid-column: -1/1;
  display: grid;
  justify-items: start;
  grid-auto-flow: column;
  height: fit-content;
  width: 100%;
  border-top: 1px solid ${({ theme }) => transparentize(0.5, theme.colors.grey500)};
  gap: 1.5rem;

  @media all and (max-width: 48em) {
    grid-template-columns: repeat(auto-fit, minmax(124px, 1fr));
    grid-auto-flow: initial;
    padding: 1.5rem 0 0;
  }

  @media all and (max-width: 36.625em) {
    padding: 1.5rem 0;
  }

  @media all and (max-width: 26.563em) {
    grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
  }
`

const StyledFooter = styled.footer`
  display: flex;
  z-index: 2;
  align-items: flex-end;
`

const FooterGrid = styled.div`
  display: grid;
  padding: 5rem 1.5rem 3rem;
  gap: 1.5rem;
  @media all and (max-width: 52.5em) {
    & > :nth-last-child(2) {
      align-self: center;
    }
  }

  @media all and (max-width: 36.625em) {
    height: auto;
    grid-template-rows: auto;
    gap: 2rem;
    padding: 0;
    padding-top: 1.5rem;
  }

  @media all and (max-width: 26.563em) {
    gap: 1rem;
  }

  @media all and (max-width: 20em) {
    gap: 0.625rem;
    padding-left: 0;
    padding-right: 0;
  }
`

const HeadingWrapper = styled.div`
  grid-column: 1/-1;
  align-self: end;
  padding-left: 0;

  @media all and (max-width: 64em) {
    padding-left: 0;
  }
`

const Heading = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 2.25rem;
  letter-spacing: -1.5px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.purple};

  padding-bottom: 3rem;
  @media all and (max-width: 64em) {
    font-size: 2rem;
    letter-spacing: -1px;
  }
  @media all and (max-width: 36.625em) {
    font-size: 1.55rem;
    letter-spacing: -0.5px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`

const Contact = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  font-weight: 300;
  padding-left: 0;

  @media all and (max-width: 64em) {
    padding: 0;
    grid-auto-flow: row;
  }

  @media all and (max-width: 48em) {
    font-size: 0.875rem;
    gap: 1rem;
  }

  @media all and (max-width: 36.625em) {
    height: fit-content;
    align-self: center;
    gap: 3rem;
  }
`

const ContactWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  height: fit-content;

  @media all and (max-width: 48em) {
    gap: 1rem;
  }
`

const ContactRow = styled.div`
  display: flex;
`

const ContactInfoWrapper = styled.div`
  display: flex;
  transition: opacity 0.5s ease, color 0.5s ease;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }

  @media (max-width: 36.625em) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export default Footer
